<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-3">
        <b-tab title="Profile" active>
          <FormUserProfile />
        </b-tab>
        <b-tab title="Change Password">
          <FromChangePassword />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import FormUserProfile from "@/view/content/profile/FormUserProfile";
import FromChangePassword from "@/view/content/profile/FromChangePassword";

export default {
  components: {
    FormUserProfile,
    FromChangePassword,
  },
};
</script>
<style></style>

<template>
  <div class="d-flex justify-content-center flex-column align-items-center">
    <h3>Đổi Mật Khẩu</h3>
    <b-form class="w-40" v-on:submit.prevent="submit">
      <b-form-group>
        <label class="mt-5" for="password">Mật khẩu hiện tại</label>
        <b-form-input
          id="password"
          type="text"
          placeholder="nhập mật khẩu"
          :state="validateState('currentPassword')"
          v-model.trim="$v.takeValueFromInput.currentPassword.$model"
          required
        >
        </b-form-input>
        <b-form-invalid-feedback> Yêu cầu nhập Email </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <label class="mt-5" for="newPassword">Mật khẩu Mới</label>
        <b-form-input
          id="newPassword"
          type="text"
          placeholder="nhập mật khẩu mới"
          :state="validateState('newPassword')"
          v-model.trim="$v.takeValueFromInput.newPassword.$model"
          required
        >
        </b-form-input>
        <b-form-invalid-feedback> Yêu cầu nhập Email </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <label class="mt-5" for="confirmPassword">Xác thật mật khẩu mới</label>
        <b-form-input
          id="confirmPassword"
          type="text"
          placeholder="nhập lại mật khẩu mới"
          :state="validateState('confirmPassword')"
          v-model.trim="$v.takeValueFromInput.confirmPassword.$model"
          required
        >
        </b-form-input>
        <b-form-invalid-feedback> Yêu cầu nhập Email </b-form-invalid-feedback>
      </b-form-group>

      <b-button class="mt-5" variant="primary" v-on:click="updatePassword">
        Cập nhật
      </b-button>
    </b-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
export default {
  mixins: [validationMixin],
  data() {
    return {
      takeValueFromInput: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  validations: {
    takeValueFromInput: {
      currentPassword: { required },
      newPassword: { required },
      confirmPassword: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.takeValueFromInput[name];
      return $dirty ? !$error : null;
    },
    updatePassword() {
      this.$v.takeValueFromInput.$touch();
      if (this.$v.takeValueFromInput.$anyError) {
        return;
      }
      const newPassword = { ...this.takeValueFromInput };
      const id = this.userInfo.id;
      ApiService.put(`/change-password/${id}`, newPassword)
        .then(() => {
          successNotification("cập nhật password thành công");
        })
        .catch(() => {
          errorNotification("cập nhật password thất bại");
        });
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
};
</script>
<style>
.w-40 {
  width: 40%;
}
</style>

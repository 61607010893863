<template>
  <div class="d-flex flex-column">
    <h3>Chỉnh Sửa Thông Tin Cá Nhân</h3>
    <b-form
      style="width: 42%"
      v-if="userInfo !== null"
      v-on:submit.prevent="submit"
    >
      <b-form-group>
        <label for="email">Email</label>
        <b-form-input
          id="email"
          type="email"
          :state="validateState('email')"
          placeholder="nhập email"
          v-model.trim="$v.form.email.$model"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.email.email">
          Yêu cầu nhập đúng định dạng Email
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-else>
          Yêu cầu nhập Email
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <label>Quyền</label>
        <b-form-select
          disabled="disabled"
          v-model.trim="$v.form.role.$model"
          :options="optionsRole"
          :state="validateState('role')"
        ></b-form-select>
        <b-form-invalid-feedback> Yêu Chọn Quyền </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <label>Phòng Ban</label>
        <b-form-select
          v-model.trim="$v.form.departmentId.$model"
          :options="optionsDepartment"
          :state="validateState('departmentId')"
        ></b-form-select>
        <b-form-invalid-feedback> Yêu Chọn Phòng Ban </b-form-invalid-feedback>
      </b-form-group>
      <b-button class="mt-5" variant="primary" v-on:click="updateProfile"
        >Cập Nhật và Lưu
      </b-button>
    </b-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
import ApiService from "@/core/services/api.service";
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  mixins: [validationMixin],
  data() {
    return {
      optionsRole: [
        { value: null, text: "Vui lòng chọn quyền" },
        { value: "user", text: "user" },
        { value: "admin", text: "admin" },
      ],
      optionsDepartment: [
        { value: null, text: "Vui lòng chọn phòng ban" },
        { value: "1", text: "Tech" },
        { value: "2", text: "HR" },
      ],
      form: {
        email: "",
        role: null,
        departmentId: null,
      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      role: { required },
      departmentId: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    updateProfile() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const newProfile = { ...this.form };
      ApiService.put(`/update-user/${this.userInfo.id}`, newProfile)
        .then(() => {
          successNotification("Cập nhật thành công , vui lòng đăng nhập lại");
          this.$store.dispatch(LOGOUT).then(() =>
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 2000)
          );
        })
        .catch((error) => {
          errorNotification("cập nhật thất bại");
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.form = { ...this.userInfo };
  },
};
</script>
<style></style>
